
import { defineComponent, computed, ref, reactive, onMounted, watch } from "vue";
import { setNewPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Pagination from "@/components/Pagination.vue";
import {
  isUndefined,
  useQuery,
  useMutateQuery,
  useHasRole
} from "@/core/helpers/common-helper";
import { convertToFullDateTimeWithoutSecond } from "@/core/helpers/date-helper";
import TableSkeleton from "@/components/skeleton/Table.vue";
import { useI18n } from "vue-i18n";
import useInvocationList from "@/core/services/compositions/invocation/useInvocationList";
import { numberFormat } from "@/core/helpers/currency-helper";
import FilterInvocation from "./filter-invocation.vue";
import { MenuComponent } from "@/assets/ts/components";
import moment from 'moment';
import { useRoute, useRouter } from "vue-router";
import { ElNotification } from 'element-plus'
import { checkPermission } from '@/router/authentication'
import useDownload from "@/core/helpers/download";

export default defineComponent({
  components: {
    Pagination,
    TableSkeleton,
    FilterInvocation,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const billHash = route.query.hash;

    // init
    const { t } = useI18n();

    // breadcrumb
    setNewPageBreadcrumbs(t("invocation.breadcrumb"), [
      { title: t("invocation.title") }
    ]);

    const paginationRef = ref(null);
    const { query } = useQuery();

    const form = reactive({
      ref_id: '',
      type: [],
      institution: '',
      va_number: '',
      bcn: '',
      channel: '',
      gateway: '',
      elapsed_time: '',
      condition: '',
      payment_terminal: '',
      status: [],
      date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      order_by: 'created_at',
      order: 'DESC',
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      invocation: '',
    })

    const fetchParams = computed(() => ({
      page: query.value.page || 1,
      limit: query.value.limit || 20,
      ref_id: query.value.ref_id || form.ref_id,
      type: typeof query.value.type == 'string' ? [query.value.type] : typeof query.value.type == 'object' ? query.value.type : form.type,
      institution: query.value.institution || form.institution,
      va_number: query.value.va_number || form.va_number,
      bcn: query.value.bcn || form.bcn,
      channel: query.value.channel || form.channel,
      gateway: query.value.gateway || form.gateway,
      elapsed_time: query.value.elapsed_time || form.elapsed_time,
      condition: query.value.condition || form.condition,
      payment_terminal: query.value.payment_terminal || form.payment_terminal,
      status: typeof query.value.status == 'string' ? [query.value.status] : typeof query.value.status == 'object' ? query.value.status : form.status,
      date: query.value.date || form.date,
      order_by: 'created_at',
      order: 'DESC',
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      invocation: query.value.invocation || form.invocation,
    }));

    const { data, isValidating, mutate: refreshList, error } = useInvocationList(() => fetchParams.value);

    watch(error, (error) => {
      if (error.response.status == 403) {
        router.push({ name: "403" });

        return;
      }
      ElNotification({
        title: error.response.data.rc,
        message: error.response.data.message,
        type: 'error',
      })
    });

    watch(query, (query) => {
      if (Object.keys(query).length === 0) {
        Object.assign(form, {
          ref_id: '',
          type: [],
          institution: '',
          va_number: '',
          bcn: '',
          channel: '',
          gateway: '',
          elapsed_time: '',
          condition: '',
          payment_terminal: '',
          status: [],
          date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
          order_by: 'created_at',
          order: 'DESC',
          ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
          invocation: ''
        });
      }
    });

    const { mutateQuery } = useMutateQuery();
    const handleChangePage = (nextPage: number) => {
      mutateQuery({
        page: nextPage,
      });
    };

    const roleUtils = useHasRole();

    const filtered = (filters) => {
      if (screen.width <= 760) {
        MenuComponent.hideDropdowns(undefined);
      }
      Object.assign(form, filters);

      mutateQuery({
        page: 1,
        ref_id: form.ref_id,
        type: form.type,
        institution: form.institution,
        va_number: form.va_number,
        bcn: form.bcn,
        channel: form.channel,
        gateway: form.gateway,
        elapsed_time: form.elapsed_time,
        condition: form.condition,
        payment_terminal: form.payment_terminal,
        status: form.status,
        date: form.date,
        order_by: 'created_at',
        order: 'DESC',
        ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
        invocation: ''
      });
    };

    const gotoEdit = (data) => {
      router.push({ name: "app.invocation-edit", params: { id: data.row.id } });
    };

    const isEditable = (data) => {
      if (data.row.type != 'pay')
        return false;

      if (data.row.status == 'failed')
        return false;

      return true;
    };

    const gotoDetail = (data) => {
      router.push({ name: "app.invocation-detail.bill", params: { id: data.row.id } });
    };

    const { handleDownloadToFile, isDownloading } = useDownload();

    const download = (file) => {
      handleDownloadToFile(`/app/invocation`, file,
        {
          download: true,
          ref_id: query.value.ref_id || form.ref_id,
          type: typeof query.value.type == 'string' ? [query.value.type] : typeof query.value.type == 'object' ? query.value.type : form.type,
          institution: query.value.institution || form.institution,
          va_number: query.value.va_number || form.va_number,
          bcn: query.value.bcn || form.bcn,
          channel: query.value.channel || form.channel,
          gateway: query.value.gateway || form.gateway,
          elapsed_time: query.value.elapsed_time || form.elapsed_time,
          condition: query.value.condition || form.condition,
          payment_terminal: query.value.payment_terminal || form.payment_terminal,
          status: typeof query.value.status == 'string' ? [query.value.status] : typeof query.value.status == 'object' ? query.value.status : form.status,
          date: query.value.date || form.date,
          order_by: 'created_at',
          order: 'DESC',
        }
      );
    }

    const gotoPayment = (id) => {
      router.push({ name: "app.payment", query: { invocation: id, date: ['', ''] } });
    };

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      ...roleUtils,
      data,
      isValidating,
      isUndefined,
      convertToFullDateTimeWithoutSecond,
      handleChangePage,
      paginationRef,
      numberFormat,
      filtered,
      moment,
      gotoEdit,
      isEditable,
      gotoDetail,
      checkPermission,
      download,
      isDownloading,
      gotoPayment
    };
  },
  methods: {
    elapsedTime: (durationInSeconds) => {
      const hrs = ~~(parseInt(durationInSeconds) / 3600);
      const mins = ~~((parseInt(durationInSeconds) % 3600) / 60);
      const secs = parseFloat(durationInSeconds) % 60;

      let elapse = "";

      if (hrs > 0) {
        elapse += "" + hrs + "h ";
      }

      if (mins > 0) {
        elapse += "" + mins + "m ";
      }

      elapse += "" + parseFloat(secs.toString()).toFixed(2) + "s";

      return elapse;
    }
  }
});
